<div class="min-h-screen h-screen max-h-screen flex flex-col sm:flex-row bg-background overflow-hidden relative">
    <!-- Menu Bar Vertical-->
    <div *ngIf="!ddss.is.xs"
        class="min-h-screen bg-[#060A25] xl:w-64 2xl:w-72 flex-none">
        <div class="w-full h-full flex flex-col items-center justify-between">

            <div class="flex flex-col flex-grow w-full">
                <!-- logo -->
                <div class="flex justify-center items-center mt-8 w-full px-3">
                    <img class="w-32 sm:w-16 xl:w-24 2xl:min-w-24"
                        src="assets/images/logo/logo-negative-rectangle.png"
                        alt="Go Talks logo">
                </div>

                <!-- Menu A - Default options -->
                <div class="flex flex-col w-full px-4 space-y-3 sm:px-6 sm:py-12 text-[#7B7F9D]">
                    <ng-container *ngFor="let item of defaultRoutes">
                        <ng-container *ngTemplateOutlet="menuButton; context: item">
                        </ng-container>
                    </ng-container>
                </div>

                <!-- Divider -->
                <div class="w-full h-1 border-t border-[#252D5C]"></div>

                <!-- Menu B - School and Suscription -->
                <div class="flex flex-col w-full px-4 space-y-3 sm:px-6 sm:py-12 text-[#7B7F9D]">
                    <ng-container *ngFor="let item of subscriptionRoutes">
                        <ng-container *ngTemplateOutlet="menuButton; context: item">
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <!-- User -->
            <div class="flex flex-row w-full items-center justify-between mb-12 px-6 relative cursor-pointer">
                <div class="flex flex-row xl:w-full gap-4 items-center mx-auto xl:mx-0">
                    <!-- <div class="hidden lg:block w-full">
                        <avatar-letter *ngIf="!user?.google_register"
                            [fullName]="user.name + ' ' + user.lastname"></avatar-letter>
                    </div> -->
                    <img *ngIf="user?.google_register"
                        class="rounded-full h-8 w-8"
                        [src]="user?.google_avatar"
                        alt="">
                    <img *ngIf="!user?.google_register"
                        class="rounded-full h-8 w-8"
                        [src]="'assets/images/misc/avatar.webp'"
                        alt="avatar">
                    <div class="hidden xl:block w-full">
                        <div class="w-full flex flex-col gap-1">
                            <div class="text-[#767676] text-sm">User</div>
                            <div class="text-white text-xs">{{user.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="hidden xl:block">
                    <mat-icon class="size-6 text-[#757575] rotate-90"
                        [svgIcon]="'chevron_right'"></mat-icon>
                </div>
            </div>
        </div>
    </div>

    <!-- Header Mobile -->
    <div *ngIf="ddss.is.xs"
        class="w-full flex flex-row items-center justify-between px-7 py-5 bg-background h-[73px] border-b border-[#192153] z-0">
        <div class="flex">
            <img class="w-24"
                src="assets/images/logo/logo-negative-rectangle.png"
                alt="Go Talks logo">
        </div>
        <div class="flex bg-[#1F50A0] rounded-full p-2 sm:p-1 items-center justify-center cursor-pointer">
            <mat-icon [svgIcon]="'notifications'"
                class="flex items-center justify-center text-white text-[1rem]"></mat-icon>
        </div>
    </div>

    <!-- Main Screen -->
    <div class="flex flex-1 sm:min-h-screen sm:h-screen w-full relative overflow-hidden">
        <div
            class="flex flex-grow min-h-full flex-col justify-center w-full h-full z-10 bg-background md:bg-transparent">
            <router-outlet></router-outlet>
        </div>
        <background-grid *ngIf="!ddss.is.xs"
            class="w-full h-full absolute z-0"></background-grid>
    </div>

    <!-- Menu Bottom -->
    <div *ngIf="ddss.is.xs"
        class="w-full flex flex-row items-center justify-around py-5 bg-[#03071C] border-t border-[#6785B6] h-[88px] z-0">

        <ng-container *ngFor="let route of routes">
            <ng-container *ngTemplateOutlet="menuButton; context: route">
            </ng-container>
        </ng-container>
    </div>

    <!-- Size Indicator -->
    <screen-size></screen-size>
</div>


<ng-template #menuButton
    let-text="text"
    let-icon="icon"
    let-route="route"
    let-disabled="disabled">
    <a [routerLink]="!disabled ? ['/' + route] : null"
        [routerLinkActive]="'bg-[#1F50A0] text-white rounded-xl'"
        class="block xl:w-full text-current no-underline"
        [class.opacity-50]="disabled"
        [class.pointer-events-none]="disabled">
        <div class="flex flex-row w-full gap-2 items-center justify-center sm:justify-between relative px-1 xl:px-4 py-1 rounded-xl"
            [ngClass]="{
                'hover:bg-[#1F50A0] hover:text-white cursor-pointer': !disabled,
                'cursor-not-allowed': disabled
            }">
            <div class="flex flex-row items-center xl:gap-2">
                <img class="size-9"
                    [src]="'assets/images/icons/' + icon + '.webp'"
                    [alt]="icon">
                <div class="hidden xl:block text-sm font-medium">
                    {{text}}
                    <span *ngIf="disabled"
                        class="text-xs text-gray-400 ml-1">(Soon)</span>
                </div>
            </div>
            <div class="hidden 2xl:block">
                <mat-icon [routerLinkActive]="'hidden'"
                    class="text-[16px] hidden xl:flex items-center justify-center"
                    [svgIcon]="'arrow_right'"
                    *ngIf="!disabled"></mat-icon>
            </div>
        </div>
    </a>
</ng-template>