import { Component } from '@angular/core';
import { NgIf } from '@angular/common'

@Component({
	selector: 'screen-size',
	standalone: true,
	imports: [ NgIf ],
	templateUrl: './screen-size.component.html',
	styleUrl: './screen-size.component.scss'
})
export class ScreenSizeComponent {
	show: boolean = true;
}
