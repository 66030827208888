import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { provideIcons } from 'app/providers/icons.providers';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { LoaderInterceptor } from 'app/interceptors/loader.interceptor';
import { jwtInterceptor } from 'app/interceptors/jwt.interceptor';
import { authInterceptor } from 'app/interceptors/auth.interceptor';

export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(routes),
		provideAnimationsAsync('noop'),
		provideHttpClient(withInterceptors([LoaderInterceptor])),
		// Custom providers for Go Talks
		provideIcons(),
		provideHttpClient(withInterceptors([jwtInterceptor])),
		provideHttpClient(withInterceptors([authInterceptor])),
	]
};