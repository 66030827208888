import { Component } from '@angular/core';
import { LoaderService } from 'app/services/loader.service';

@Component({
	selector: 'loader',
	standalone: true,
	imports: [],
	templateUrl: './loader.component.html',
	styleUrl: './loader.component.scss'
})
export class LoaderComponent {
	constructor(public loaderService: LoaderService) {}
}
