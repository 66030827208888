import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgIf, NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon'

import { DeviceDetectorService } from 'app/services/device-detector.service';
import { WizardService } from 'app/services/wizard.service'

import { BackButtonComponent } from 'app/modules/shared/back-button/back-button.component';
import { BackgroundGridComponent } from 'app/modules/shared/background-grid/background-grid.component';
import { CircleLoaderComponent } from 'app/modules/shared/circle-loader/circle-loader.component';
import { ProgressBarComponent } from 'app/modules/shared/progress-bar/progress-bar.component';
import { ScreenSizeComponent } from 'app/modules/shared/screen-size/screen-size.component';

@Component({
    selector: 'app-wizard-layout',
    standalone: true,
    imports: [RouterOutlet, NgIf, NgClass, ScreenSizeComponent, BackgroundGridComponent, BackButtonComponent, ProgressBarComponent, MatIcon, CircleLoaderComponent],
    templateUrl: './wizard-layout.component.html',
    styleUrl: './wizard-layout.component.scss'
})
export class WizardLayoutComponent implements OnInit {

    percentage: number = 0;
    allowedContinue: boolean = false;
    allowedBack: boolean = false;
    wizardEnded: boolean = false;
    lastStep: boolean = false;

    constructor(
        public ddss: DeviceDetectorService,
        public wizardService: WizardService
    ) { }

    ngOnInit(): void {
        this.wizardService.onBack.next(false);
        this.wizardService.percentageProgress$.subscribe(percentage => this.percentage = percentage);
        this.wizardService.onAllowedContinue.subscribe(allowed => this.allowedContinue = allowed);
        this.wizardService.onAllowedBack.subscribe(allowed => this.allowedBack = allowed);
        this.wizardService.wizardEnded$.subscribe(wizardEnded => this.wizardEnded = wizardEnded);
        this.wizardService.lastStep.subscribe(lastStep => this.lastStep = lastStep);
    }

    click() {
        if (this.allowedContinue) {
            this.wizardService.onContinue.next(true)
        }
    }

    clickBack() {
        if (this.allowedBack) {
            this.wizardService.onBack.next(true);
        }
    }
}
