import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { LoaderService } from 'app/services/loader.service';
import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

export const LoaderInterceptor: HttpInterceptorFn = (req, next) => {
	const loaderService = inject(LoaderService);
	loaderService.show();

	return next(req).pipe(
		finalize(() => {
			loaderService.hide();
		})
  );
};