import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';


import { LoaderComponent } from 'app/loader/loader.component';


@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        NgIf,
        RouterOutlet,
        LoaderComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    host: { 'class': 'flex flex-col min-h-screen' }
})
export class AppComponent {
    loading = false;

    constructor(private router: Router) {
        /*this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                console.log("route loaded");
                this.loading = true;
            }
            if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                console.log("route loading");
                this.loading = false;
            }
        });*/
    }
}
