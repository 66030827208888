import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgTemplateOutlet, NgIf, NgFor, NgClass } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { User } from 'app/types/user.type';

import { MatIconModule } from '@angular/material/icon';

import { AvatarLetterComponent } from 'app/modules/shared/avatar-letter/avatar-letter.component';
import { BackgroundGridComponent } from 'app/modules/shared/background-grid/background-grid.component';
import { DeviceDetectorService } from 'app/services/device-detector.service';
import { ScreenSizeComponent } from 'app/modules/shared/screen-size/screen-size.component';
import { UserService } from 'app/services/user.service';

interface Route {
	text: string
	icon: string
	route: string
	disabled: boolean
}

@Component({
	selector: 'app-school-layout',
	standalone: true,
	imports: [ RouterOutlet, NgTemplateOutlet, MatIconModule, RouterLink, RouterLinkActive, AvatarLetterComponent, ScreenSizeComponent, NgIf, NgFor, NgClass, BackgroundGridComponent ],
	templateUrl: './school-layout.component.html',
	styleUrl: './school-layout.component.scss'
})
export class SchoolLayoutComponent {
	user: User;

	routes: Route[] = [
		{text: 'Colaboradores', icon: 'people', route: 'school/collaborator', disabled: false},
		{text: 'Aulas', icon: 'door', route: 'school/classroom', disabled: false},
		{text: 'Tareas', icon: 'calendar-2', route: 'school/homework', disabled: false},
		{text: 'Retroceder', icon: 'back-arrow', route: 'practice', disabled: false},
	]

	// Separar las rutas en dos grupos
	defaultRoutes = this.routes.slice(0, 3);
	subscriptionRoutes = this.routes.slice(3);
	/**
     * Constructor
     */
    constructor(
		public ddss: DeviceDetectorService,
        private userService: UserService
    )
    {
    }

	ngOnInit(): void
    {
		// Subscribe to user changes
        this.user = this.userService.user;
	}
}
