import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { catchError, switchMap, throwError } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
	const authService = inject(AuthService);
	const router = inject(Router);
	
	return next(request).pipe(
		catchError((error) => {
		if (error instanceof HttpErrorResponse && error.status === 401) {
			return authService.signInUsingToken().pipe(
			switchMap((success: boolean) => {
				if (success) {
					// Actualizar el request con el nuevo token
					const newRequest = request.clone({
						setHeaders: {
						Authorization: `Bearer ${authService.accessToken}`
						}
					});
					return next(newRequest);
				} else {
					// Si el refresh falla, redirigir al login
					const currentUrl = router.url;
					router.navigate(['/sign-in'], {
						queryParams: { returnUrl: currentUrl }
				});
				return throwError(() => new Error('Token refresh failed'));
				}
			}),
			catchError((err) => {
					// Si hay un error en el refresh, redirigir al login
					const currentUrl = router.url;
					router.navigate(['/sign-in'], {
					queryParams: { returnUrl: currentUrl }
				});
				return throwError(() => err);
			})
			);
		}
		return throwError(() => error);
		})
	);
};