import { Component } from '@angular/core';

@Component({
  selector: 'circle-loader',
  standalone: true,
  imports: [],
  templateUrl: './circle-loader.component.html',
  styleUrl: './circle-loader.component.scss'
})
export class CircleLoaderComponent {

}
