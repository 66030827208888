import { Routes } from '@angular/router';
import { AuthLayoutComponent } from 'app/layouts/auth-layout/auth-layout.component';
import { AppLayoutComponent } from 'app/layouts/app-layout/app-layout.component';
import { ChatLayoutComponent } from 'app/layouts/chat-layout/chat-layout.component';
import { WizardLayoutComponent } from 'app/layouts/wizard-layout/wizard-layout.component';
import { SchoolLayoutComponent } from 'app/layouts/school-layout/school-layout.component';
import { authGuard } from 'app/guards/auth.guard';

export const routes: Routes = [

    // Learn routes
    {
        path: '',
        canActivate: [authGuard],
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'practice',
                //redirectTo: 'learn',
                pathMatch: 'full'
            },
            {
                path: 'learn',
                loadComponent: () => import('app/modules/learn/learn.component').then(c => c.LearnComponent)
            },
            {
                path: 'practice',
                loadComponent: () => import('app/modules/practice/practice-router/practice-router.component').then(c => c.PracticeRouterComponent),
                children: [
                    {
                        path: '',
                        loadComponent: () => import('app/modules/practice/practice.component').then(c => c.PracticeComponent),
                    },
                    {
                        path: 'roleplaying',
                        loadComponent: () => import('app/modules/roleplaying/roleplaying-router/roleplaying-router.component').then(c => c.RoleplayingRouterComponent),
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/roleplaying/roleplaying.component').then(c => c.RoleplayingComponent),
                            },
                            {
                                path: 'preview',
                                loadComponent: () => import('app/modules/generic-roleplaying/preview/preview.component').then(c => c.GenericRoleplayingPreviewComponent)
                            },
                        ]
                    },
                    {
                        path: 'picture-description',
                        loadComponent: () => import('app/modules/picture-description/picture-description-router/picture-description-router.component').then(c => c.PictureDescriptionRouterComponent),
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/picture-description/picture-description.component').then(c => c.PictureDescriptionComponent),
                            },
                            {
                                path: 'viewer',
                                loadComponent: () => import('app/modules/picture-description/picture-description-viewer/picture-description-viewer.component').then(c => c.PictureDescriptionViewerComponent),
                            },
                            {
                                path: 'rewards',
                                loadComponent: () => import('app/modules/picture-description/picture-description-rewards/picture-description-rewards.component').then(c => c.PictureDescriptionRewardsComponent),
                            }
                        ]
                    },
                    {
                        path: 'custom-conversation',
                        loadComponent: () => import('app/modules/custom-conversation/custom-conversation.component').then(c => c.CustomConversationComponent)
                    },
                    {
                        path: 'character',
                        loadComponent: () => import('app/modules/character/character-router/character-router.component').then(c => c.CharacterRouterComponent),
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/character/character.component').then(c => c.CharacterComponent)
                            },
                            {
                                path: 'preview',
                                loadComponent: () => import('app/modules/generic-roleplaying/preview/preview.component').then(c => c.GenericRoleplayingPreviewComponent)
                            }
                        ]
                    }
                ]
            },
            {
                path: 'profile',
                loadComponent: () => import('app/modules/profile/profile.component').then(c => c.ProfileComponent)
            },
            {
                path: 'dictionary',
                loadComponent: () => import('app/modules/dictionary/dictionary.component').then(c => c.DictionaryComponent)
            },
            {
                path: 'subscription',
                loadComponent: () => import('app/modules/subscription/subscription-router/subscription-router.component').then(c => c.SubscriptionRouterComponent),
                children: [
                    {
                        path: '',
                        loadComponent: () => import('app/modules/subscription/subscription.component').then(c => c.SubscriptionComponent)
                    },
                    {
                        path: 'all',
                        loadComponent: () => import('app/modules/subscription/subscription-all/subscription-all.component').then(c => c.SubscriptionAllComponent)
                    },
                ]
            }


        ]
    },

    // School
    {
        path: 'school',
        canActivate: [authGuard],
        children: [
            {
                path: '',
                component: AppLayoutComponent,
                canActivate: [authGuard],
                children: [
                    {
                        path: '',
                        loadComponent: () => import('app/modules/school/school.component').then(c => c.SchoolComponent)
                    }
                ]
            },
            {
                path: 'wizard',
                children: [
                    {
                        path: '',
                        component: WizardLayoutComponent,
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/school/wizard/wizard.component').then(c => c.WizardComponent)
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dashboard',
                children: [
                    {
                        path: '',
                        component: SchoolLayoutComponent,
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/school/dashboard/dashboard.component').then(c => c.DashboardComponent)
                            }
                        ]
                    }
                ]
            },
            {
                path: 'collaborator',
                children: [
                    {
                        path: '',
                        component: SchoolLayoutComponent,
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/school/collaborator/collaborator.component').then(c => c.CollaboratorComponent)
                            }
                        ]
                    },
                    {
                        path: 'create',
                        component: WizardLayoutComponent,
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/school/collaborator/collaborator-wizard/collaborator-wizard.component').then(c => c.CollaboratorWizardComponent)
                            }
                        ]
                    }
                ]
            },
            {
                path: 'classroom',
                children: [
                    {
                        path: '',
                        component: SchoolLayoutComponent,
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/school/classroom/classroom.component').then(c => c.ClassroomComponent)
                            }
                        ]
                    },
                    {
                        path: 'create',
                        component: WizardLayoutComponent,
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/school/classroom/classroom-wizard/classroom-wizard.component').then(c => c.ClassroomWizardComponent)
                            }
                        ]
                    },
                    {
                        path: 'edit',
                        component: SchoolLayoutComponent,
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/school/classroom/classroom-form/classroom-form.component').then(c => c.ClassroomFormComponent)
                            }
                        ]
                    }
                ]
            },
            {
                path: 'homework',
                children: [
                    {
                        path: '',
                        component: SchoolLayoutComponent,
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/school/homework/homework.component').then(c => c.HomeworkComponent)
                            }
                        ]
                    },
                    {
                        path: 'create',
                        component: WizardLayoutComponent,
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('app/modules/school/homework/wizard/wizard.component').then(c => c.WizardComponent)
                            }
                        ]
                    }
                ]
            }
        ]
    },

    // Chat
    {
        path: 'chat',
        component: ChatLayoutComponent,
        canActivate: [authGuard],
        children: [
            {
                path: '',
                loadComponent: () => import('app/modules/chat/chat.component').then(c => c.ChatComponent)
            }
        ]
    },

    // auth routes
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'sign-in',
                loadComponent: () => import('app/modules/auth/sign-in/sign-in.component').then(c => c.SignInComponent)
            },
            {
                path: 'sign-up',
                loadComponent: () => import('app/modules/auth/sign-up/sign-up.component').then(c => c.SignUpComponent)
            },
            {
                path: 'password-recovery',
                loadComponent: () => import('app/modules/auth/password-recovery/password-recovery.component').then(c => c.PasswordRecoveryComponent)
            },
            {
                path: 'register',
                loadComponent: () => import('app/modules/auth/register/register.component').then(c => c.RegisterComponent)
            },
            {
                path: 'verify-email',
                loadComponent: () => import('app/modules/auth/verify-email/verify-email.component').then(c => c.VerifyEmailComponent)
            },
            {
                path: 'forgot-password',
                loadComponent: () => import('app/modules/auth/forgot-password/forgot-password.component').then(c => c.ForgotPasswordComponent)
            },
            {
                path: 'test',
                loadComponent: () => import('app/modules/auth/test/test.component').then(c => c.TestComponent)
            }
        ]
    },

    // Test routes
    {
        path: '',
        //redirectTo: '/learn',
        redirectTo: '/practice',
        pathMatch: 'full'  // Esto asegura que la redirección se aplique solo cuando la URL esté completamente vacía
    },
    { path: '**', redirectTo: '/', pathMatch: 'full' },
];
