<div class="min-h-screen h-screen max-h-screen flex flex-row bg-background overflow-hidden relative">

    <back-button class="top-3 lg:top-2 lg:left-2 xl:top-5 xl:left-5 absolute z-50" [style]="'back-mobile'"></back-button>
    <div class="flex min-h-screen h-screen w-full relative">
        <div class="w-full h-screen z-30 flex items-center justify-center">
            <div class="w-full h-full">
                <router-outlet></router-outlet>
            </div>
            
        </div>
        
        <background-grid class="w-full h-full absolute z-10"></background-grid>
    </div>

    <screen-size></screen-size>
</div>