import { Component, Input } from '@angular/core';
import { NgFor, NgIf, NgClass } from '@angular/common';

import { DeviceDetectorService } from 'app/services/device-detector.service';

@Component({
	selector: 'background-grid',
	standalone: true,
	imports: [ NgFor, NgIf, NgClass ],
	templateUrl: './background-grid.component.html',
	styleUrl: './background-grid.component.scss'
})
export class BackgroundGridComponent {
	
	@Input() ballPosition: 'left' | 'center' | 'right' = 'center'

	totalLines: number = 40;

	constructor(
		public ddss: DeviceDetectorService
	) {}
}
