import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/services/auth.service';

// Lista de URLs que no necesitan token
const whiteList = [
  'texttospeech.googleapis.com'
];

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const token = authService.accessToken;

  // Verificar si la URL está en el whitelist
  const isWhitelisted = whiteList.some(url => req.url.includes(url));
  
  if (token && !isWhitelisted) {
    const clonedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next(clonedReq);
  }

  return next(req);
};