import { Component, Input } from '@angular/core';

@Component({
	selector: 'avatar-letter',
	standalone: true,
	imports: [],
	templateUrl: './avatar-letter.component.html',
	styleUrl: './avatar-letter.component.scss'
})
export class AvatarLetterComponent {
	@Input() fullName: string
}
