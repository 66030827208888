import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from 'app/services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
	const authService = inject(AuthService);
	const router = inject(Router);
  
	if (authService.isAuthenticated()) {
	  	return true;
	} else {
		// Redirigir al login si no está autenticado
		return router.createUrlTree(['/sign-in'], { queryParams: { returnUrl: state.url }});
	}
};
