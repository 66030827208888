import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgTemplateOutlet, NgIf } from '@angular/common';

import { ScreenSizeComponent } from 'app/modules/shared/screen-size/screen-size.component';
import { BackgroundGridComponent } from 'app/modules/shared/background-grid/background-grid.component';
import { BackButtonComponent } from 'app/modules/shared/back-button/back-button.component';

@Component({
	selector: 'app-chat-layout',
	standalone: true,
	imports: [ RouterOutlet, NgTemplateOutlet, NgIf, ScreenSizeComponent, BackgroundGridComponent, BackButtonComponent ],
	templateUrl: './chat-layout.component.html',
	styleUrl: './chat-layout.component.scss'
})
export class ChatLayoutComponent {

}
