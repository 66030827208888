import { Component, ViewEncapsulation, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { NgIf, NgFor, NgStyle } from '@angular/common';
import { RouterOutlet } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { AvatarService } from 'app/services/avatar.service';
import { DeviceDetectorService } from 'app/services/device-detector.service';

import { AvatarVariant } from 'app/types/avatar/avatar.type';

import { BackgroundGridComponent } from 'app/modules/shared/background-grid/background-grid.component';

@Component({
	selector: 'app-auth-layout',
	standalone: true,
	imports: [NgIf, NgFor, NgStyle, RouterOutlet, MatIconModule, MatButtonModule, BackgroundGridComponent],
	templateUrl: './auth-layout.component.html',
	styleUrl: './auth-layout.component.scss',
	encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent implements OnInit {
	n: number = 50;
	showWindows: boolean = false;
	variant: AvatarVariant

	@ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;

	constructor(
		private ngZone: NgZone,
		public avatarService: AvatarService,
		public dd: DeviceDetectorService
	) {
	}

	ngOnInit(): void {
		this.avatarService.getRandomVariant().then( (variant: AvatarVariant) => {
			this.variant = variant;
		} )
	}

	ngAfterViewInit() {
		this.ngZone.runOutsideAngular(() => {
		setTimeout(() => {
			this.playVideo();
		}, 0);
		});
	}

	getArray(): number[] {
		return Array(this.n);
	}

	playVideo() {
		if (this.videoPlayer && this.videoPlayer.nativeElement) {
			const video = this.videoPlayer.nativeElement;
			video.muted = true;
			video.play().then(() => {
				console.log('Video started playing successfully');
			}).catch(error => {
				console.error('Error playing video:', error);
				// Intenta reproducir nuevamente después de una interacción del usuario
				document.body.addEventListener('click', () => {
				video.play().catch(e => console.error('Error on click play:', e));
				}, { once: true });
			});
		}
	}
}
