<div class="min-h-screen h-screen max-h-screen flex flex-col w-full flex-grow bg-background overflow-hidden relative">

    <back-button class="top-3 lg:top-2 lg:left-2 xl:top-5 xl:left-5 absolute z-50"
        [style]="'close'"></back-button>

    <div
        class="grow h-full flex flex-col w-full justify-center lg:max-w-4xl xl:max-w-5xl m-0 md:mt-8 lg:mt-20 xl:mt-14 mx-auto overflow-y-hidden">

        <ng-container *ngIf="ddss.is.xs">
            <div class="w-full p-4 pl-12 flex flex-row justify-between border-b-2 border-[#1F50A0]">
                <img class="w-20"
                    src="assets/images/logo/logo-negative-rectangle.png"
                    alt="Go Talks logo">
                <div class="flex bg-[#1F50A0] rounded-full p-2 sm:p-1 items-center justify-center cursor-pointer">
                    <mat-icon [svgIcon]="'notifications'"
                        class="flex items-center justify-center text-white text-[1rem]"></mat-icon>
                </div>
            </div>
        </ng-container>

        <progress-bar class="w-full z-20 p-4 pt-8 sm:p-0"
            [color]="'blue'"
            [progress]="percentage"
            [height]="12"></progress-bar>

        <div class="w-full flex grow h-full flex-col mx-auto max-w-md p-8 pt-16 z-30 overflow-y-hidden">
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="flex w-full border-t-2 border-[#1F50A0] p-8 sm:p-0 h-auto sm:h-36 z-20">
        <div class="flex flex-row gap-1 w-full h-full items-center lg:max-w-4xl xl:max-w-5xl mx-auto"
            [ngClass]="{
            'justify-between': allowedBack,
            'justify-end': !allowedBack
        }">
            <!-- Back -->
            <div (click)="clickBack()"
                class="items-center justify-center w-full sm:w-auto rounded-xl p-4 px-16 border bg-background border-gt-border-button text-white cursor-pointer button-hover"
                [ngClass]="{
                    'flex': allowedBack,
                    'hidden': !allowedBack
                }">
                Atrás
            </div>
            <!-- Continue -->
            <div (click)="click()"
                [ngClass]="{
                'border-gt-border-button button-with-background text-white cursor-pointer button-hover':allowedContinue,
                'text-[#B2B2B2] border-[#9197B3]':!allowedContinue
            }"
                class="flex items-center justify-center w-full sm:w-auto rounded-xl p-4 px-16 border">
                <ng-container *ngIf="!wizardEnded && !lastStep">Continuar</ng-container>
                <ng-container *ngIf="!wizardEnded && lastStep">Finalizar</ng-container>
                <circle-loader *ngIf="wizardEnded"></circle-loader>
            </div>
        </div>
    </div>

    <background-grid [ballPosition]="'left'"
        class="w-full h-full absolute z-10 opacity-70"></background-grid>
    <screen-size></screen-size>
</div>