<!-- Background-->
<div class="w-full h-full relative">
    <ng-container *ngIf="ddss.isGreaterEqual('sm')">
        <div class="w-full flex flex-col md:space-y-8 xl:-space-y-16 md:z-10 absolute bottom-0">
            <div *ngFor="let dummy of [].constructor(totalLines); let i = index" class="w-full line-gradient-horizontal"></div>
        </div>
    
        <div class="h-full flex flex-row md:space-x-8 xl:-space-x-16 md:z-20 absolute right-0">
            <div *ngFor="let dummy of [].constructor(totalLines); let i = index" class="h-full line-gradient-vertical"></div>
        </div>
    
        <div [ngClass]="{
            'radial-full-background': ballPosition == 'center',
            'radial-full-background-left': ballPosition == 'left',
        }" class="absolute z-0"></div>
    </ng-container>
    
</div>